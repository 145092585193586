import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let Theme = createTheme({
  palette: {
    mode: "dark",
    type: 'dark',
    primary: {
      main: '#e5a00d',
    },
    secondary: {
      main: '#e5a00d',
    },
  },
  typography: {
    fontFamily: 'Inter',
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
    fontWeightBold: 800,
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  shape: {
    borderRadius: 10,
  },
});
Theme = responsiveFontSizes(Theme);
export default Theme;