import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { fireAuth } from "../config/FireBase.config.tsx";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  FormContainer,
  TextFieldElement,
  PasswordElement,
  PasswordRepeatElement,
} from "react-hook-form-mui";

export default function Create() {
  const handleCreate = (e) => {
    createUserWithEmailAndPassword(fireAuth, e.email, e.password)
      .then(() => {
        let a = document.createElement("a");
        a.style.display = "none";
        a.href = "https://mazi.pw/user";
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => {
        console.log("Error");
      });
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box paddingTop={10}>
            <center>
              <Typography component="h4" variant="h4">
                Create a Mazi account
              </Typography>
              <Typography component="h4" variant="subtitle1">
                Create a Mazi account to be able to use the Mazi bot.
              </Typography>
              <FormContainer onSuccess={(data) => handleCreate(data)}>
                <TextFieldElement
                  name="email"
                  type="email"
                  label="Email"
                  required
                  sx={{ marginTop: 10, width: "100%", maxWidth: "400px" }}
                />
                <br />
                <PasswordElement
                  name="password"
                  label="Password"
                  required
                  sx={{ marginTop: 2, width: "100%", maxWidth: "400px" }}
                />
                <br />
                <PasswordRepeatElement
                  passwordFieldName="password"
                  name="password-repeat"
                  label="Repeat Password"
                  required
                  sx={{ marginTop: 2, width: "100%", maxWidth: "400px" }}
                />
                <br />
                <Button size="large" type="Submit" sx={{ marginTop: 2 }}>
                  Submit
                </Button>
              </FormContainer>
            </center>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
}
