import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { fireAuth } from "../config/FireBase.config.tsx";
import { sendPasswordResetEmail } from "firebase/auth";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

export default function Forgot() {
  const handleForgot = (e) => {
    sendPasswordResetEmail(fireAuth, e.email)
      .then(() => {
        alert("Please check your email for reset link.");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box paddingTop={10}>
            <center>
              <Typography component="h4" variant="h4">
                Reset your Mazi password
              </Typography>
              <Typography component="h4" variant="subtitle1">
                We all forget our passwords sometimes, don't worry.
              </Typography>
              <FormContainer onSuccess={(data) => handleForgot(data)}>
                <TextFieldElement
                  name="email"
                  type="email"
                  label="Email"
                  required
                  sx={{ marginTop: 10, width: "100%", maxWidth: "400px" }}
                />
                <br />
                <Button size="large" type="Submit" sx={{ marginTop: 2 }}>
                  Submit
                </Button>
              </FormContainer>
            </center>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
}