import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { BrowserView, MobileView } from 'react-device-detect';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  FormContainer,
  TextFieldElement,
  PasswordElement,
  SelectElement,
} from "react-hook-form-mui";
import { fireAuth } from "../config/FireBase.config.tsx";
import { fireStore } from "../config/FireBase.config.tsx";
import { useAuthUser } from "../config/AuthUser.tsx";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import Tooltip from "@mui/material/Tooltip";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import {
  collection,
  query,
  where,
  getDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

export default function User() {
  const user = useAuthUser();
  const [tooltip, setToolTip] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [discordButton, setDiscordButton] = React.useState("Link Discord Account");
  const [plexButton, setPlexButton] = React.useState("Link Plex Account");
  const [plexServerURL, setPlexServerURL] = React.useState("Link Plex Server");
  const [plexServerButton, setPlexServerButton] = React.useState("Submit");
  const [plexLibraryButton, setPlexLibraryButton] = React.useState("Submit");
  const [plexError, setPlexError] = React.useState("");
  const [apiurl, setapiurl] = React.useState("https://api.mazi.pw");
  const [options, setOptions] = React.useState([]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  async function handleDeleteData() {
    if (window.confirm("Really delete your information?")) {
      var id = user!.uid;
      var docRef = doc(fireStore, "userdata", id);
      var docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await deleteDoc(docRef);
        alert("Deleted Information.");
        window.location.reload();
      } else {
        alert("No Information to Delete.");
      }
    } else {
      alert("Did not delete information.");
    }
  };
  async function checkstatus(user) {
    var id = user!.uid;
    var docRef = doc(fireStore, "userdata", id);
    var docSnap = await getDoc(docRef);
    if (!docSnap.exists()) { 
      console.log("User has not added data yet.");
      return;
    } else {
      const data = docSnap.data();
      if (data.discord === true) {
        setDiscordButton(data.discordemail);
        setActiveStep(1);
      }
      if (data.plex === true) {
        setPlexButton(data.plexemail);
        setActiveStep(2);
      }
      if (data.plex === true && data.discord !== true) {
        setActiveStep(0);
      }
      if (data.plexserver) {
        setPlexServerURL(data.plexserver);
        setPlexServerButton("Update");
        handleGetLibraries();
        setActiveStep(3);
      }
      if (data.plexlibrary) {
        options.push({
          id: data.plexlibrary,
          label: data.plexlibrary,
        });
        setPlexLibraryButton("Update");
        setActiveStep(4); 
      }
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  async function handleGetLibraries() {
    var id = user!.uid;
    var email = user!.email;
    var docRef = doc(fireStore, "userdata", id);
    var docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      console.log("No user found");
      return;
    } else {
      const data = docSnap.data()
        if (data.plexserver && data.plex === true) {
          fetch(`${apiurl}/getplexlibraries?email=${email}`)
          .then(function(response) {
            if (!response.ok) {
              setPlexError("Error getting libraries. Please check your url is correct.");
              return Promise.reject(response);
            }
            return response.json();
          })
          .then((data) => setOptions(data))
          .catch((e) => console.log(e))
        }
    }
  }
  async function handleStep3() {
    handleGetLibraries();
    handleNext();
  }
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleToolTip = () => {
    setToolTip(false);
  };
  const handleLogin = (e) => {
    signInWithEmailAndPassword(fireAuth, e.email, e.password).catch((e) => {
      setToolTip(true);
    });
  };
  const handleSignOut = () => {
    signOut(fireAuth).then(() => {
      console.log("Have a nice day.");
    });
  };
  const handlePlexServerSubmit = (e) => {
    fetch(`${apiurl}/plexserver?email=${user!.email}&serverurl=${e.url}`)
      .then((r) => {
        alert("Success adding Plex Server URL.")
        window.location.reload();
      })
      .catch((e) => alert("Error."));
  };
  const handlePlexLibrarySubmit = (e) => {
    fetch(`${apiurl}/plexlibrary?email=${user!.email}&library=${e.library}`)
      .then((r) => {
        alert("Success adding Server Library.")
        window.location.reload();
      })
      .catch((e) => alert("Error."));
  };
  React.useEffect(() => {
    if (user) {
      checkstatus(user);
    }
  }, [user]);
  React.useEffect(() => {
    if (user) {
      handleStep3();
    }
  }, []);
  if (user) {
    const steps = [
      { label: "Link Discord Account" },
      { label: "Link Plex account" },
      { label: "Add Plex Server" },
      { label: "Add Plex Library" },
    ];
    return (
      <Box sx={{minHeight: "100vh", display: "flex"}}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <BrowserView>
            <Button
              onClick={handleSignOut}
              sx={{
                display: "flex",
                position: "fixed",
                bottom: 0,
                marginBottom: 5,
                marginLeft: 5,
              }}
            >
              Log Out
            </Button>
            <Button
              onClick={handleDeleteData}
              sx={{
                display: "flex",
                position: "fixed",
                bottom: 0,
                marginBottom: 10,
                marginLeft: 5,
              }}
            >
              Delete Data
            </Button>
            </BrowserView>
          </Grid>
          <Grid item xs={10}>
            <center>
              <Box paddingTop={10} sx={{ width: "100%", maxWidth: 800 }}>
                <Typography component="h4" variant="h4">
                  Welcome back <span id="username">{user.email}</span>!
                </Typography>
                <Typography
                  component="h4"
                  variant="subtitle1"
                  sx={{ paddingBottom: 5 }}
                >
                  Manage your Mazi links so you can use the bot.
                </Typography>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          index === 2 || index === 3 ? (
                            <Typography variant="caption">Optional</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        {index === 0 ? (
                          <Box sx={{ paddingBottom: 5, paddingTop: 5 }}>
                            <Button onClick={() => {
                              window.open(`${apiurl}/discordlink?email=${user.email}`)
                            }}>
                              {discordButton}
                            </Button>
                          </Box>
                        ) : null}
                        {index === 1 ? (
                          <Box sx={{ paddingBottom: 5, paddingTop: 5 }}>
                            <Button onClick={() => {
                              window.open(`${apiurl}/plexlink?email=${user.email}`)
                            }}>
                              {plexButton}
                            </Button>
                          </Box>
                        ) : null}
                        {index === 2 ? (
                          <Box sx={{ paddingBottom: 5, paddingTop: 5 }}>
                            <FormContainer
                              onSuccess={(data) => handlePlexServerSubmit(data)}
                            >
                              <TextFieldElement
                                placeholder={plexServerURL}
                                name="url"
                                label="Plex Server URL"
                                required
                                sx={{ width: "100%", maxWidth: "400px" }}
                              />
                              <br />
                              <Button
                                size="large"
                                type="Submit"
                                sx={{ marginTop: 2 }}
                              >
                                {plexServerButton}
                              </Button>
                            </FormContainer>
                          </Box>
                        ) : null}
                        {index === 3 ? (
                          <Box sx={{ paddingBottom: 5, paddingTop: 5 }}>
                            <FormContainer
                              onSuccess={(data) =>
                                handlePlexLibrarySubmit(data)
                              }
                            >
                              <Typography component="h3" variant="subtitle1" sx={{paddingBottom: 2}}>
                                {plexError}
                                </Typography>
                              <SelectElement
                                label="Library Selection"
                                name="library"
                                sx={{ width: "100%", maxWidth: "400px" }}
                                options={options}
                              />
                              <br />
                              <Button
                                size="large"
                                type="Submit"
                                sx={{ marginTop: 2 }}
                                disabled={options.length === 0}
                              >
                                {plexLibraryButton}
                              </Button>
                            </FormContainer>
                          </Box>
                        ) : null}
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={index === 2 ? handleStep3 : handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Continue"}
                            </Button>
                            {index !== 0 ? (
                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Back
                              </Button>
                            ) : null}
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper elevation={0} sx={{ p: 3 }}>
                    <Typography>
                      All steps completed - you can now host Watch together
                      Sessions
                    </Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Go back
                    </Button>
                  </Paper>
                )}
                <MobileView>
                  <center>
                <Button
              onClick={handleSignOut}
              sx={{
                display: "flex",
                bottom: 0,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Log Out
            </Button>
            <Button
              onClick={handleDeleteData}
              sx={{
                display: "flex",
                bottom: 0,
                marginBottom: 10,
              }}
            >
              Delete Data
            </Button>
            </center>
                </MobileView>
              </Box>
            </center>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Box paddingTop={10}>
              <center>
                <Typography component="h4" variant="h4">
                  Log in to Mazi
                </Typography>
                <Typography component="h4" variant="subtitle1">
                  Log in to your Mazi account to manage your{" "}
                  <span id="PlexText">Plex</span> and{" "}
                  <span id="DiscordText">Discord</span> account links.
                </Typography>
                <FormContainer onSuccess={(data) => handleLogin(data)}>
                  <TextFieldElement
                    name="email"
                    type="email"
                    label="Email"
                    required
                    sx={{ marginTop: 10, width: "100%", maxWidth: "400px" }}
                  />
                  <br />
                  <PasswordElement
                    name="password"
                    label="Password"
                    required
                    sx={{ marginTop: 2, width: "100%", maxWidth: "400px" }}
                  />
                  <br />
                  <Tooltip
                    open={tooltip}
                    onClose={handleToolTip}
                    title="Incorrect Credentials"
                  >
                    <Button size="large" type="Submit" sx={{ marginTop: 2 }}>
                      Submit
                    </Button>
                  </Tooltip>
                </FormContainer>
                <ButtonGroup
                  variant="text"
                  sx={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <Button href="/forgot">Forgot Password</Button>
                  <Button href="/create">Create Account</Button>
                </ButtonGroup>
              </center>
            </Box>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Box>
    );
  }
}
