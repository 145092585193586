import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import "@fontsource/inter";
import "./index.css"
import { ThemeProvider } from "@mui/material/styles";
import Theme from './components/theme';
import Main from "./pages/main.tsx";
import User from "./pages/user.tsx";
import Create from "./pages/create.tsx";
import Forgot from "./pages/forgot.tsx";
export default function App() {
  return (
      <Router>
        <ThemeProvider theme={Theme}>
          <div>
              <Routes>
                  <Route path='/' element={<Main />} />
                  <Route path='/user' element={<User />} />
                  <Route path='/create' element={<Create />} />
                  <Route path='/forgot' element={<Forgot />} />
                  <Route path='/*' element={<Main />} />
              </Routes>
          </div>
        </ThemeProvider>
      </Router>
  );
}