import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MaziLogo from "../assets/mazilogolight.png";
import FadeIn from "react-fade-in";

export default function Main() {
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box paddingTop={10}>
            <center>
              <FadeIn delay={300} transitionDuration={700}>
                <Typography component="h4" variant="h4">
                  Mazi, the Plex Watch Together Discord bot
                </Typography>
                  <ButtonGroup
                    variant="text"
                    sx={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <Button href="/user">User Panel</Button>
                    <Button href="https://bot.mazi.pw" target="_blank">
                      Status
                    </Button>
                    <Button
                      href="https://discord.com/api/oauth2/authorize?client_id=978163786886311977&permissions=326417591296&scope=bot%20applications.commands"
                      target="_blank"
                    >
                      Invite
                    </Button>
                    <Button href="https://discord.gg/47SnjxgBFb" target="_blank">
                      Discord
                    </Button>
                    <Button
                      href="https://github.com/Wamy-Dev/Mazi"
                      target="_blank"
                    >
                      Github
                    </Button>
                  </ButtonGroup>
                <img src={MaziLogo} alt="Mazi Logo" width="300" />
              </FadeIn>
            </center>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}